import React, { useState, useRef } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { useHistory } from "react-router-dom";
import { useOutsideClick } from "helpers/hooks/useOutsideClick";
import { Modal } from "reactstrap";
import ModalActivationIntegration from "../ModalActivationIntegration";

const CardContentWhatsapp = ({ item, icon }) => {
  const [enabled, setEnabled] = useState(item.integration ? true : false);
  const history = useHistory();
  const modalRef = useRef();
  const [showAction, setShowAction] = useState(false);
  const [showModalToggle, setShowModalToggle] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onOpenDetail = paramsItem => {
    if (paramsItem) {
      history.push(`/integration/whatsapp/detail`);
    } else {
      history.push(`/integration/whatsapp`);
    }
  };

  const findStatus = paramsStatus => {
    switch (paramsStatus) {
      case "register":
        return <span className="status">Menunggu Proses Integrasi</span>;
      case "review_policy":
        return <span className="status">Menunggu Proses Integrasi</span>;
      case "platform_review":
        return <span className="status">Dalam Review</span>;
      case "active":
        return <span className="status">Terintegrasi</span>;
      case "deactivated":
        return <span className="error">Integrasi gagal, silahkan ulang</span>;
      default:
        return "Belum ada data dari BE";
    }
  };

  useOutsideClick(() => {
    setShowAction(false);
  }, modalRef);

  const onOpenModal = () => {
    setShowModalToggle(!showModalToggle);
  };

  return (
    <>
      <div
        ref={modalRef}
        className="page-integration-menu"
        onClick={() => onOpenDetail(item.integration)}
      >
        <div className="page-integration-menu-title">
          {icon}
          <div className="page-integration-status">
            <span className="title">{item.platform_name}</span>
            {item.integration ? findStatus(item.integration.status) : ""}
          </div>
        </div>
        <div className="page-integration-action">
          <div className="action-chevron">
            <ChevronRightIcon className="icon-chevron" />
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModalToggle}
        toggle={onOpenModal}
        className="modal-integration"
        centered
      >
        <ModalActivationIntegration
          enabled={enabled}
          name={item.platform_name}
          error={error}
          loading={loading}
          onUpdateIntegration={() => onOpenModal()}
          setShowModalToggle={() => setShowModalToggle(false)}
        />
      </Modal>
    </>
  );
};

export default CardContentWhatsapp;
