import { JWT_TOKEN_KEY } from "network/http/jwt-token-access";
import React, { useEffect } from "react";
import { Spinner } from "reactstrap";

const EmbedChat = ({ location, data }) => {
  const getAdmin = () => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      return obj;
    }
  };

  useEffect(() => {
    window.risa_account = {
      ...data,
      admin_user: getAdmin(),
      authorization: localStorage.getItem(JWT_TOKEN_KEY),
      additional_header: {
        "X-RISA-AGENT-TYPE": "user",
      },
      location,
    };

    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = process.env.REACT_APP_WIDGET_CHAT_JS_URL;
    s1.charset = "UTF-8";
    s1.type = "text/javascript";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  }, [data]);

  return (
    <div id="risa-ai-embed" style={{ textAlign: "center" }}>
      <Spinner />
    </div>
  );
};

export default EmbedChat;
