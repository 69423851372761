import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import RegisterAuthStepVerification from "pages/Authentication/RegisterAuthStepVerification";

// Dashboard
// import Dashboard from "../pages/Dashboard/index"
import DashboardSaas from "../pages/Dashboard-saas/index";

//Ecommerce Pages
import EcommerceDashboard from "../pages/Ecommerce/EcommerceDashboard/EcommerceDashboard";
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
// import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";
import ServiceKnowledge from "../pages/Ecommerce/ServiceKnowledge/ServiceKnowledgeList";
import AddServiceKnowledge from "../pages/Ecommerce/ServiceKnowledge/AddServiceKnowledge";
import AddServiceKnowledgeQuestion from "../pages/Ecommerce/ServiceKnowledge/AddServiceKnowledgeQuestion";
import DetailServiceKnowledgeQuestion from "../pages/Ecommerce/ServiceKnowledge/DetailServiceKnowledgeQuestion";
import ProductDetails from "../pages/Ecommerce/ProductDetails/ProductDetail";
import ProductRecommend from "../pages/Ecommerce/ProductRecommend/ProductRecommend";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import Shop from "pages/Shop";

// CHAT
import Chat from "pages/Chat/Chat";
import Rating from "pages/Rating/ReviewList";
// import LiveChat from "pages/LiveChat";

// DIAGRAM FLOW
import DiagramFlow from "pages/DiagramFlow/index";
import AgentProfile from "pages/AgentIntent/AgentProfile";

// Ticket
// import TicketList from "pages/Ticket/TicketList";
// import TicketDetail from "pages/Ticket/TicketDetail";

import InvoiceList from "pages/Invoice/InvoiceList";

import EmailVerification from "pages/Authentication/EmailVerification";
import ValidateEmailVerification from "pages/Authentication/ValidateEmailVerification";
import ResetPassword from "pages/Authentication/ResetPassword";
import CompleteInvitationUser from "pages/Authentication/CompleteInvitationUser";

// Onboarding
import OnboardingPage from "pages/Onboarding";
import Integration from "pages/Ecommerce/Integration";
import Auth0VendorSuccess from "pages/Authentication/Auth0VendorSuccess";

// Knowledge
import Knowledge from "pages/Knowledge";
import TestChatScenario from "pages/TestChatScenario";
// import LiveChatEmbed from "pages/LiveChat/LiveChatEmbed";

// Integration
import IntegrationPage from "pages/Integration/Integration";
import IntegrationTokopedia from "pages/Integration/Integration-tokopedia/Integration-tokopedia";
import SummaryIntegration from "pages/Integration/Integration-tokopedia/Summary-integration";
import IntegrationWhatsapp from "pages/Integration/Integration-whatsapp/Integration-whatsapp";
import IntegrationWhatsappDetail from "pages/Integration/Integration-whatsapp/Integration-whatsapp-detail";

//Settings
import Settings from "pages/Settings/Settings";
import IntegrationWeb from "pages/Integration/IntegrationWeb/IntegrationWeb";

const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardSaas },
  // { path: "/dashboard-saas", component: DashboardSaas },

  //profile
  { path: "/settings", component: Settings },

  { path: "/chat", component: Chat },
  { path: "/rating", component: Rating },
  { path: "/chat/:conversationId", component: Chat },
  { path: "/ai-assistant/profile", component: AgentProfile },

  //Ecommerce
  { path: "/ecommerce", component: EcommerceDashboard },
  { path: "/ecommerce-products", component: EcommerceProducts },
  { path: "/ecommerce-integration", component: Integration },
  { path: "/ecommerce-products/category/:catId", component: EcommerceProducts },
  { path: "/ecommerce-products/store/:storeId", component: EcommerceProducts },
  { path: "/ecommerce-product-details/:id", component: ProductDetails },
  { path: "/ecommerce-orders", component: EcommerceOrders },
  { path: "/ecommerce-customers", component: EcommerceCustomers },
  { path: "/ecommerce-shops", component: EcommerceShops },
  { path: "/ecommerce-add-product", component: EcommerceAddProduct },
  { path: "/ecommerce-product-recommend", component: ProductRecommend },

  //Ecommerce
  { path: "/service-knowledge", component: ServiceKnowledge },
  { path: "/add-service-knowledge", component: AddServiceKnowledge },
  {
    path: "/add-service-knowledge-question/:id",
    component: AddServiceKnowledgeQuestion,
  },
  {
    path: "/detail-service-knowledge-question/:id",
    component: DetailServiceKnowledgeQuestion,
  },

  // Billing
  { path: "/billing-invoice", component: InvoiceList },

  // Ticket
  // { path: "/ticket-list", component: TicketList },
  // { path: "/ticket-detail/:id", component: TicketDetail },

  // Shop detail
  { path: "/store/:id", component: Shop },

  //
  { path: "/knowledge", component: Knowledge },

  // Integration
  { path: "/integration", component: IntegrationPage },
  { path: "/integration/tokopedia", component: IntegrationTokopedia },
  { path: "/integration/tokopedia/summary", component: SummaryIntegration },
  { path: "/integration/whatsapp", component: IntegrationWhatsapp },
  {
    path: "/integration/whatsapp/detail",
    component: IntegrationWhatsappDetail,
  },
  { path: "/integration/web", component: IntegrationWeb },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:userId/:token", component: ResetPassword },
  { path: "/register", component: Register },
  { path: "/auth0-redirect", component: Auth0VendorSuccess },
  {
    path: "/register/auth-two-step-verification",
    component: RegisterAuthStepVerification,
  },
  { path: "/ai-builder/:platform", component: DiagramFlow },
  { path: "/scenario-test/:scenarioId", component: TestChatScenario },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/pages-pricing", component: PagesPricing },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/user/verify/:userId/:token", component: ValidateEmailVerification },
  // { path: "/live-chat/embed", component: LiveChatEmbed },
  // { path: "/live-chat", component: LiveChat },

  // Invitation
  { path: "/user/complete-invitation", component: CompleteInvitationUser },
  { path: "/user/email-verification", component: EmailVerification },

  // Onboarding
  { path: "/onboarding", component: OnboardingPage },
];

export { authProtectedRoutes, publicRoutes };
